.formContentBox[data-v-72f8bca8] {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain[data-v-72f8bca8] {
  width: 100%;
  overflow: auto;
  height: 100%;
}
.uploadhide[data-v-72f8bca8] .el-upload,
.uploadhide[data-v-72f8bca8] .el-upload--picture-card {
  display: none !important;
}